import { Col, Container, Row, Nav, Navbar } from "react-bootstrap";
import "./App.css";
import Web3 from "web3";
import { useEffect, useState } from "react";
import { AiFillPlusCircle, AiFillMinusCircle } from "react-icons/ai";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import Carousel from "./Ca";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import abi from "./abi.json";
import AOS from "aos";
import "aos/dist/aos.css";
AOS.init();

function App() {
  const [web3, setWeb3] = useState("");
  const [add, setAdd] = useState("");
  const [instance, setInstance] = useState(null);
  const [mintNumber, setMintNumber] = useState(1);
  const [totalMinted, setTotalMinted] = useState(0);

  const [whitelist, setWhitelist] = useState([
    "0x53C3daf583d035Fa0601555685d8C7De9693b238",
    "0xA8030C3Cb442f913c84AE3339D580Bd9C723db16",
    "0x8dC25968351B07255839602F310D0Ad7319F0043",
    "0xC76aD3AeEc331202800794A579bbcBBfdf922324",
  ]);

  useEffect(() => {
    let web3 = new Web3(Web3.givenProvider);
    setWeb3(web3);

    const ins = new web3.eth.Contract(
      abi,
      "0xd0C9cb046A6a6bdC8293855569E94b0a48A4f01f"
    );
    setInstance(ins);

    if (window.ethereum) {
      const getTotalMinted = async () => {
        var tokenCount = await ins.methods.totalSupply().call();
        setTotalMinted(tokenCount);
      };
      getTotalMinted();
    }
  }, []);

  async function requestAccount() {
    console.log("Requesting account...");
    if (window.ethereum) {
      console.log("detected");

      try {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setAdd(accounts[0]);
      } catch (error) {
        console.log("Error connecting...");
      }
    } else {
      window.open("https://metamask.app.link/dapp/tikimoji.io/");
    }
  }

  // const mint = async () => {
  //   if (add) {
  //     const lower = whitelist.map((element) => {
  //       return element.toLowerCase();
  //     });
  //     if (lower.includes(add)) {
  //       if (instance) {
  //         console.log(
  //           await instance.methods.mint(mintNumber).send({
  //             from: add,
  //             value: (
  //               (await instance.methods.cost().call()) * mintNumber
  //             ).toString(),
  //           })
  //         );
  //       } else {
  //         console.log("error");
  //       }
  //     } else {
  //       alert("you are not whitelisted.");
  //     }
  //   } else {
  //     alert("Please Connect your wallet");
  //   }
  // };

  const mint = async () => {
    if (add) {
      const lower = whitelist.map((element) => {
        return element.toLowerCase();
      });
      if (lower.includes(add)) {
        if (instance) {
          console.log(
            await instance.methods.mint(mintNumber).send({
              from: add,
              value: (
                (await instance.methods.cost().call()) * mintNumber
              ).toString(),
            })
          );
        } else {
          console.log("error");
        }
      } else {
        alert("you are not whitelisted.");
      }
    } else {
      alert("Please Connect your wallet");
    }
  };

  const plus = () => {
    if (mintNumber < 2) setMintNumber(mintNumber + 1);
  };
  const minus = () => {
    if (mintNumber > 1) {
      setMintNumber(mintNumber - 1);
    }
  };

  return (
    <>
      <div className="app">
        <div className="scene">
          <Container className="mt-3">
            <div className="cus-nav">
              <Container>
                <Navbar className="cus-nav" variant="dark" expand="lg">
                  <Navbar.Brand
                    href="#top"
                    className="font-weight-bold text-uppercase"
                  >
                    <img
                      className="img-fluid navlogo"
                      src={require("./assets/img/logo.png").default}
                      alt=""
                    />
                  </Navbar.Brand>

                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto d-flex align-items-center">
                      <Nav.Link href="#top" className="link-style linkh">
                        Home
                      </Nav.Link>
                      <Nav.Link href="#about" className="link-style linkh">
                        About
                      </Nav.Link>
                      <Nav.Link href="#hunt" className="link-style linkh">
                        Tiki Hunt
                      </Nav.Link>
                      <Nav.Link
                        className="text-white fcursive font-weight-bold link-style linkh"
                        href="#founder"
                      >
                        founder
                      </Nav.Link>
                      <Nav.Link href="#faq" className="link-style linkh">
                        Faq
                      </Nav.Link>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Container>
            </div>
          </Container>

          <Container className="mt-5">
            <Row>
              <Col>
                <div className="">
                  <div className="mint">
                    <h3
                      style={{ color: "#fff" }}
                      className="text-center mih1 text-uppercase"
                    >
                      Buy 1 Get 1 Free
                    </h3>
                    <div className="text-center mt-4 ">
                      <div onClick={requestAccount} className="btn cusbtn">
                        <span className="text-white text-uppercase">
                          {add ? add.substring(0, 15) : "Connect Wallet"}
                        </span>
                      </div>
                      <div className="d-flex justify-content-center align-items-center mt-5">
                        <div className="mx-1">
                          <AiFillMinusCircle onClick={minus} size={80} />
                        </div>
                        <div className="display-number d-flex justify-content-center align-items-center">
                          <span className="number">{mintNumber}</span>
                        </div>
                        <div className="mx-1">
                          <AiFillPlusCircle onClick={plus} size={80} />
                        </div>
                      </div>
                      <div className="text-center mt-3">
                        <img
                          style={{ cursor: "pointer" }}
                          onClick={mint}
                          className="img-fluid"
                          src={require("./assets/img/mintlogo.png").default}
                          alt=""
                        />
                      </div>
                      <div className="text-center mt-4">
                        <h3 className="font-weight-bold bfont">
                          {totalMinted} / 3333
                        </h3>
                      </div>
                      <div className="">
                        {/* <a
                          href="https://www.instagram.com/draconft/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            src={require("./assets/img/Instagram.png").default}
                            alt=""
                          />
                        </a> */}
                        <a
                          href="https://twitter.com/TikimojiNFT"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            src={require("./assets/img/Twitter.png").default}
                            alt=""
                          />
                        </a>
                        {/* <a
                          href="https://discord.gg/qkY3vDwV4W"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            src={require("./assets/img/Discord.png").default}
                            alt=""
                          />
                        </a> */}

                        <a
                          href="https://opensea.io/collection/tikimoji"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            className="si"
                            src={require("./assets/img/Opensea.png").default}
                            alt=""
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="">
          <img
            className="img-fluid"
            src={require("./assets/img/ti.png").default}
            alt=""
          />
        </div>

        <div className="">
          <Container>
            <div className="">
              <div className="text-center">
                <div style={{ marginTop: "6rem" }} className="font-weight-bold">
                  <div className="">
                    <Container data-aos="fade-up" data-aos-duration="2000">
                      <Row className="">
                        <Col className="d-flex align-items-bottom w-100">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="w-100 bb  mx-2"
                          >
                            <p className="subtitle text-center text-pink font-weight-bold">
                              TIKI ISLAND COMING SOON
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="d-flex align-items-bottom" lg="6">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="bb w-100 mx-2"
                          >
                            <p className="subtitle text-center text-blue font-weight-bold">
                              NO ROADMAP
                            </p>
                          </div>
                        </Col>
                        <Col className="rmt-3 d-flex align-items-bottom" lg="6">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="w-100 bb mx-2"
                          >
                            <p className="subtitle text-center text-blue font-weight-bold">
                              {/* NO WHITELIST */}
                              GOOD VIBES
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="w-100 d-flex align-items-bottom">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="w-100 bb  mx-2"
                          >
                            <p className="subtitle text-center text-pink font-weight-bold">
                              LIVE GAME SHOW.
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="d-flex align-items-bottom" lg="6">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="bb w-100 mx-2"
                          >
                            <p className="subtitle text-center text-blue font-weight-bold">
                              ONE TIKIMOJI = TWO TIKIMOJI
                            </p>
                          </div>
                        </Col>
                        <Col className="rmt-3 d-flex align-items-bottom" lg="6">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="w-100 bb mx-2"
                          >
                            <p className="subtitle text-center text-blue font-weight-bold">
                              ONE TIKIMOJI = ONE TICKET TO TIKI ISLAND
                            </p>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-5">
                        <Col className="w-100 d-flex align-items-bottom">
                          <div
                            data-aos="fade-up"
                            data-aos-duration="2000"
                            className="w-100 bb  mx-2"
                          >
                            <p className="subtitle text-uppercase text-center text-pink font-weight-bold">
                              ARE YOU READY TO EXPERIENCE THE TIME OF YOUR LIFE?
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>

        <div id="about" className="about-content">
          <div id="" className="">
            <Container className="">
              <div className="text-center">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className="title"
                >
                  About
                </h1>
              </div>
              <Row className="con-1 h-100">
                <Col
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  lg="6"
                  className="d-flex align-items-center justify-content-center"
                >
                  <div className="">
                    <img
                      className="img-fluid"
                      src={require("./assets/img/gif.gif").default}
                      alt=""
                    />
                  </div>
                </Col>
                <Col
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  lg="6"
                  className="rmt-3 d-flex flex-column justify-content-between"
                >
                  <div className="">
                    <div className="">
                      <span style={{ fontSize: "1.3rem" }} className="">
                        Tikimoji was just an idea in a High School Marketing
                        Class. Originally set out to be an Apparel Line, our
                        founder wanted to be more original and offer something
                        that Tikimoji holders can actually engage in. We plan to
                        give you guys some of the most unforgettable experiences
                        in Web3. Tikimoji has now formed into a project by a
                        very inspired artist who wants to share that same love
                        and inspiration that he has for NFT’s to his Web3 peers.
                        Our Exclusive LIVE GAME SHOW “Tiki Hunt” was designed to
                        bring those nostalgic memories of watching your favorite
                        game shows when TV was actually fun to watch. Why not
                        bring that energy into this space, giving you amazing
                        degens opportunities to win Prizes. Anybody can watch
                        from the couch, or their office desk.. Why not win from
                        there too while you’re at it?
                      </span>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      WE LOVE YOU GUYS SO MUCH! YOUR SUPPORT MEANS THE WORLD TO
                      US
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <div id="hunt" className="roadmap ">
        <Container className=" h-100">
          <Row className=" h-100">
            <Col lg="12" className="">
              <div className="">
                <h1
                  data-aos="fade-up"
                  data-aos-duration="2000"
                  className=" text-center"
                >
                  TIKI HUNT
                </h1>

                <div className=" mx-1 text-wrapper mt-5 mb-5">
                  <p
                    data-aos="fade-up"
                    data-aos-duration="2000"
                    style={{ fontSize: "1.3rem" }}
                    className="mt-5"
                  >
                    Tiki Hunt is the BEST LIVE GAME SHOW OF ALL-TIME! Strictly
                    built off of chance, this game is designed to give each
                    player a sense of urgency with hint of confusion. No
                    Tokenomics. No Strategies. No Brainstorming. Just Luck. If
                    you think you can conquer the hunt. Find your way over to
                    Tiki Island and WIN SOME PRIZES!
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="mt-5">
        <Carousel />
      </div>

      <Container id="founder" className="about-content">
        <Container className="">
          <div className="text-center">
            <h1 data-aos="fade-up" data-aos-duration="2000" className="title">
              Founder Info
            </h1>
          </div>
          <Row className="con-1 h-100">
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="6"
              className="d-flex align-items-center justify-content-center"
            >
              <div className="">
                <img
                  style={{ borderRadius: "2rem" }}
                  className="img-fluid"
                  src={require("./assets/img/ape.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              data-aos="fade-up"
              data-aos-duration="2000"
              lg="6"
              className="rmt-3 d-flex justify-content-center align-items-center"
            >
              <div className="">
                <div className="">
                  <span style={{ fontSize: "1.3rem" }} className="">
                    We’d like for you guys to meet the brains behind this
                    amazing project. Known in Web3 as “NFTMummy” most know him
                    to be Mummy Doco. A dope Hip Hop Artist out of North
                    Carolina. In making music of almost 10 years, he’s been
                    blessed to work with multi-platinum producers &amp;
                    engineers, not to mention, he's brought his talents to the
                    Web3 Space by dropping music on the blockchain. This is
                    where he's found his truest supporters and networking
                    capabilities. After being into NFT’S for a couple years,
                    he’s ready to drop his own collection, spreading love to
                    those that supports him through his journey as an artist.
                    Making 200+ Hand Drawn Traits and creating a well designed
                    game that he will host LIVE for Tikimoji holders to
                    participate in for exclusive prizes. With the help of some
                    amazing developers, he was able to bring his idea that
                    birthed over a decade ago to life.
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        </Container>

        <Row>
          <Col className="" lg="12">
            <div className="">
              <p
                style={{ fontSize: "1.2rem" }}
                data-aos="fade-up"
                data-aos-duration="2000"
                className=""
              ></p>
            </div>
          </Col>
        </Row>
      </Container>
      <div id="faq" className="faq text-center">
        <div className="d-flex justify-content-center align-items-center">
          <h1
            data-aos="fade-up"
            data-aos-duration="2000"
            className="title font-weight-bold text-uppercase"
          >
            FAQ
          </h1>
        </div>

        <Container className="mt-5">
          <Row>
            <Col lg="12">
              <Accordion>
                <AccordionItem
                  className="faqedit"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is supply?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">3,333</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How much does a Tikimoji cost?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      .015 Whitelist // .0175 Public Buy One Get One FREE
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How many can I mint?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">3 per wallet</p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      What is Tiki Hunt?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      Tiki Hunt is our Newly Designed Game that will be streamed
                      as a LIVE GAME SHOW for our holders to participate in for
                      prizes.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem
                  className="faqedit mt-3"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className="con-bot text-center">
                      How can I play Tiki Hunt?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="text-dark">
                      Simply hold at least one Tikimoji NFT in your wallet and
                      submit into our weekly raffles.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>

      <div className="footer">
        <Container>
          <Row>
            <Col lg="6">
              <div className="rtc">
                <img
                  className="img-fluid navlogo"
                  src={require("./assets/img/logo.png").default}
                  alt=""
                />
              </div>
            </Col>
            <Col
              className="rmt-2 d-flex mtc align-items-center justify-content-end"
              lg="6"
            >
              <div className="">
                <span className="font-weight-bold">
                  © {new Date().getFullYear()} Tikimoji NFT Collection.
                </span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default App;
